import { render, staticRenderFns } from "./DragonCard.vue?vue&type=template&id=2a1d90c4&scoped=true&"
import script from "./DragonCard.vue?vue&type=script&lang=ts&"
export * from "./DragonCard.vue?vue&type=script&lang=ts&"
import style0 from "./DragonCard.vue?vue&type=style&index=0&id=2a1d90c4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a1d90c4",
  null
  
)

export default component.exports