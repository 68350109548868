var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{staticClass:"card",attrs:{"to":{
    name: 'ItemDetail',
    params: {
      id: _vm.item.id
    }
  }},nativeOn:{"mouseover":function($event){return _vm.onMouseOverHandler()},"mouseleave":function($event){return _vm.onMouseLeaveHandler()}}},[_c('div',{staticClass:"card__inner",style:({
      background: ("url(" + _vm.backgroundGif + ") center / cover no-repeat"),
    })},[(_vm.item.auction)?_c('div',{staticClass:"card__auction-timer"},[(_vm.item.auction.expires_in > 0)?_c('countdown',{attrs:{"end-time":new Date().getTime() + _vm.item.auction.expires_in * 1000},scopedSlots:_vm._u([{key:"process",fn:function(ref){
    var timeObj = ref.timeObj;
return [_c('div',{class:{
              'card__auction-timer-inner': true,
              'card__auction-timer-inner--almost--expired': timeObj.leftTime <= 3600000
            }},[_c('span',{staticClass:"card__timer-item"},[_vm._v(" "+_vm._s(timeObj.d)+"d ")]),_c('span',{staticClass:"card__timer-item"},[_vm._v(" "+_vm._s(timeObj.h)+"h ")]),_c('span',{staticClass:"card__timer-item"},[_vm._v(" "+_vm._s(timeObj.m)+"m ")]),_c('span',{staticClass:"card__timer-item"},[_vm._v(" "+_vm._s(timeObj.s)+"s ")])])]}}],null,false,627855127)}):_c('div',{staticClass:"card__auction-timer-inner"},[_c('span',{staticClass:"card__timer-item"},[_vm._v(" "+_vm._s(_vm.auctionLabelText)+" ")])])],1):_vm._e(),_c('div',{staticClass:"card__content"},[_c('div',{staticClass:"card__img-wrp"},[(_vm.item.item.gif)?_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.gifLoaded),expression:"gifLoaded"}],staticClass:"card__img",attrs:{"src":_vm.item.item.gif},on:{"load":function($event){return _vm.onGifLoaded()}}}):_vm._e(),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.gifLoaded || !_vm.item.item.gif),expression:"!gifLoaded || !item.item.gif"}],staticClass:"card__img",attrs:{"src":_vm.item.item.image}})]),_c('div',{staticClass:"card__info"},[_c('div',{staticClass:"card__center"},[_c('div',{staticClass:"card__center-marks"},[(_vm.item.item.ability)?_c('span',{class:[
                'card__mark',
                ("card__mark--affinity--" + (_vm.getCardClassByDependece(_vm.item.item.ability)))
              ]},[_vm._v(" "+_vm._s(_vm.item.item.ability)+" ")]):_vm._e(),_c('span',{class:[
                'card__mark',
                ("card__mark--tag--" + (_vm.getCardClassByDependece(_vm.item.item.rarity)))
              ]},[_vm._v(" "+_vm._s(_vm.item.item.rarity)+" ")])]),_c('h1',{staticClass:"card__title"},[_vm._v(_vm._s(_vm.item.item.title))])])]),_c('div',{staticClass:"card__bottom"},[_c('img',{staticClass:"card__bottom-background-img",attrs:{"src":_vm.bottomBackgroundGif}}),_c('div',{staticClass:"card__bottom-content"},[(_vm.cardOptions.stockTextVisible)?_c('span',{staticClass:"card__stock"},[_vm._t("stockContent",function(){return [_vm._v(" Price ")]},{"stock":_vm.stock})],2):_vm._e(),_c('div',{staticClass:"card__price"},[_vm._t("priceContent",function(){return [_c('transition',{attrs:{"name":"text-appear-from-bottom","mode":"out-in"}},[(_vm.isCardHovered)?_c('span',{staticClass:"card__price-hover-text"},[(_vm.item.auction)?_c('span',[_vm._v("Place a bid")]):_c('span',[_vm._v("Buy now")])]):_c('div',{staticClass:"card__price-content"},[(_vm.isOnSale)?_c('img',{class:[
                      'card__price-content-icon',
                      ("card__price-content-icon--currency--" + (_vm.currency.toLowerCase()))
                    ],attrs:{"src":'https://d1ak9kdvdopy8s.cloudfront.net/' + _vm.priceIcon,"alt":""}}):_vm._e(),_c('span',{staticClass:"card__price-text"},[_vm._v(_vm._s(_vm.priceText))])])])]})],2)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }