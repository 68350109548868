export function roundPrice(price: number, currency = 'BNB') {
  const isInt = price % 1 === 0;

  const decimals = {
    BNB: isInt ? 0 : 2,
    TCG2: 0,
  };
  const resolved = typeof decimals[currency] === 'number' ? decimals[currency] : 2;

  if (Number(price.toFixed(resolved)) === 0) {
    const zerosAmount = -1 - Math.floor(Math.log10(price % 1)) || 2;
    return price.toFixed(zerosAmount + 1);
  }

  return price.toFixed(resolved);
}
